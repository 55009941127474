<template>
  <el-dialog title="opc类型映射选择表"
             :close-on-click-modal="false"
             :visible.sync="opcDialog"
             width="50%"
             append-to-body>
    <div>
      <span style="width: 10%">opc名称:</span>
      <el-input v-model="opcName" style="margin-left: 10px; width: 40%" disabled></el-input>
      <div style="margin-top: 20px">
        <span style="margin-left: 15%">数据项名称</span>
        <span style="margin-left: 40%">属性列表</span>
        <div style="margin-left: 1%;margin-top: 1%;">
          <div>
            <el-row :gutter="70">
              <el-col :span="11">
                <div>
                  <el-table
                      ref="selectDeviceField"
                      :data="deviceFieldData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      :header-cell-class-name="cellClass"
                      @select="deviceFieldSelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        min-width="30%"
                        :selectable="selectDeviceStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="数据项名称"
                        min-width="70%">
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="数据项类型"
                        min-width="70%">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
              <el-col :span="11">
                <div>
                  <el-table
                      ref="selectOpc"
                      :data="opcInfoData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      :header-cell-class-name="cellClass"
                      @select="opcSelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        min-width="30%"
                        :selectable="selectOpcStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="数据列表"
                        min-width="70%">
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;">
        <el-row :gutter="50">
          <el-col :span="17">
            <div>
              <el-table
                  :data="selectOpcData"
                  height="250"
                  border
                  style="width: 100%">
                <el-table-column
                    prop="selectDeviceField"
                    label="已选择设备字段"
                    min-width="40%">
                </el-table-column>
                <el-table-column
                    prop="selectOpcField"
                    label="已选择属性列表"
                    min-width="40%">
                </el-table-column>
                <el-table-column label="操作" min-width="20%">
                  <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        @click="handleDeleteOpc(scope.$index, scope.row)">删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span="7">
            <div>
              <el-button @click="selectOpcMap" style="width: 100%;height: 5%;padding:5px 10px;font-size: 10px"
                         type="primary" round>确定
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <span slot="footer" class="dialog-footer">
       <el-button @click="opcDialog = false">取 消</el-button>
       <el-button type="primary" @click="opcSure">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "opc",
  props: ["workNode", "nowReflectData", "mapData", "nowEachTaskData", "taskData", "allTaskData", "opcData"],
  data() {
    return {
      opcDialog: false,
      opcName: '',
      deviceFieldSearch: '',
      deviceFieldData: [],
      deviceFieldDataCopy: [],
      opcInfoSearch: '',
      opcInfoDataCopy: [],
      opcInfoData: [],
      selectOpc: {},
      selectOpcSecond: {},
      selectDeviceField: {},
      selectDeviceSecondField: {},
      selectOpcData: [],
      nowOneReflectOpc: {},
      oneReflectOpcName_temp: {
        OpcName: '',
        opc: []
      },
      nowOneReflectOpcName: {},
      oneReflectOpc_temp: {
        opcField: '',
        opc: '',
        fieldName: '',
        fieldId: ''
      },
    }
  },
  methods: {

    open(data) {
      this.selectOpcData = data
      this.opcDialog = true
    },
    close() {
      this.opcDialog = false
    },
    loadDeviceFieldData(id, data) {
      console.log("data", data)
      console.log("opcdata", this.opcData)
      this.opcName = data.opcName
      this.deviceFieldData = [];
      // let temp = {
      //   coal: this.workNode.parent.parent.parent.parent.data.name,
      //   system: this.workNode.parent.parent.parent.data.name,
      //   subsystem: this.workNode.parent.parent.data.name,
      //   device: this.workNode.parent.data.name,
      //   subDevice: this.workNode.data.name,
      //   subEquipmentId: this.workNode.data.subEquipmentId
      // }
      this.postRequest("/universaltable/getOtherLeafItem?tableId="+this.workNode.data.levelId+"&id="+this.workNode.data.id).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          let tmpData = {
            name: response.data.data[i].name,
            type: response.data.data[i].type,
            // fieldId: response.data[i].fieldId,
            fieldId: response.data.data[i].id,
            flag: '未选中',
            seen: false
          }
          if(tmpData.type === undefined || tmpData.type === ""){
            tmpData.type = "未设定"
          }
          console.log("data123:"+tmpData)
          this.deviceFieldData.push(tmpData)
        }
      }).then(error => {
      })

      //
      // this.postRequest("/addDataAccessTask/attributeSubDevice", temp).then(response => {
      //   for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
      //     let tmpData = {
      //       name: response.data[i].name,
      //       fieldId: response.data[i].fieldId,
      //       type: response.data[i].type,
      //       flag: '未选中',
      //       seen: false
      //     }
      //     this.deviceFieldData.push(tmpData)
      //   }
      // }).then(error => {
      //
      // })

      for (let i = 0; i < this.opcData.length; i++) {
        if (id === this.opcData[i].id) {
          this.opcInfoData = []
          this.selectOpcData = []
          let data = {
            ip: this.opcData[i].node.data.ip,
            port: this.opcData[i].node.data.port,
            username: this.opcData[i].node.data.username,
            password: this.opcData[i].node.data.password,
            uid: this.opcData[i].node.data.uid,
            device:this.opcData[i].node.data.name,
            passagewayName:this.opcData[i].node.parent.data.name,
          }
          // let tmpData = {
          //   name: "test1231",
          //   seen: false,
          //   flag: '未选中'
          // }
          // this.opcInfoData.push(tmpData)
            this.postRequest("/addDataAccessTask/getTagByDevice",data).then(response => {
              console.log("response.data", response.data)
              let resData = response.data;
              // for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
              for (let i = 0; i < response.data.length; i++) {
                let data = {
                  name: resData[i],
                  seen: false,
                  flag: '未选中'
                }
                this.opcInfoData.push(data)
              }
              console.log("data"+data);
            }).then(error => {
              // console.log(error)
            })


          let same = 0
          for (let m = 0; m < this.nowReflectData.Opc.length; m++) {
            if (this.opcData[i].opcName === this.nowReflectData.Opc[m].OpcName) {
              this.nowOneReflectOpcName = JSON.parse(JSON.stringify(this.nowReflectData.Opc[m]))
              same = 1
              break
            }
          }
          if (same === 0) {
            this.nowOneReflectOpcName = JSON.parse(JSON.stringify(this.oneReflectOpcName_temp))
            this.nowOneReflectOpcName.OpcName = this.opcData[i].opcName
            this.nowReflectData.Opc.push(this.nowOneReflectOpcName)
          }
          console.log("this.nowReflectData", this.nowReflectData)
          for (let m = 0; m < this.nowReflectData.Opc.length; m++) {
            if (this.opcData[i].opcName === this.nowReflectData.Opc[m].OpcName) {
              for (let n = 0; n < this.nowReflectData.Opc[m].opc.length; n++) {
                if (this.opcData[i].opcName === this.nowReflectData.Opc[m].opc[n].opc) {
                  let data = {
                    selectOpc: this.nowReflectData.Opc[m].opc[n].opc,
                    selectOpcField: this.nowReflectData.Opc[m].opc[n].opcField,
                    selectDeviceField: this.nowReflectData.Opc[m].opc[n].fieldName,
                    fieldId: this.nowReflectData.Opc[m].opc[n].fieldId,
                  }
                  this.selectOpcData.push(data)
                }
              }
              break;
            }
          }

        }
      }

      setTimeout(() => {
        for (let i = 0; i < this.mapData.length; i++) {
          for (let j = 0; j < this.opcInfoData.length; j++) {
            if (this.mapData[i].tableData === this.opcInfoData[j].name && this.mapData[i].tableName === this.opcName) {
              this.opcInfoData[j].flag = '已选中2'
            }
          }
        }
        for (let i = 0; i < this.mapData.length; i++) {
          for (let j = 0; j < this.deviceFieldData.length; j++) {
            if (this.mapData[i].deviceData === this.deviceFieldData[j].name) {
              this.deviceFieldData[j].flag = '已选中2'
            }
          }
        }
        for (let i = 0; i < this.opcInfoData.length; i++) {
          if (this.opcInfoData[i].flag === '已选中2') {
            this.$refs.selectOpc.toggleRowSelection(this.opcInfoData[i], true)
          }
          this.$options.methods.selectOpcStatus(this.opcInfoData[i])
        }
        for (let j = 0; j < this.deviceFieldData.length; j++) {
          if (this.deviceFieldData[j].flag === '已选中2') {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[j], true)
          }
          this.$options.methods.selectDeviceStatus(this.deviceFieldData[j])
        }
      }, 6000)

      this.opcDialog = true
      // }
    },
    loseFocus(index, row) {
      row.seen = false;
    },
    cellClick(row, column) {
      row.seen = true;
    },
    deviceFieldSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      this.selectDeviceField = row
      if (this.selectDeviceSecondField.fieldId === undefined) {
        this.selectDeviceSecondField = this.selectDeviceField
      } else {
        for (let i = 0; i < this.deviceFieldData.length; i++) {
          if (this.selectDeviceSecondField.fieldId === this.deviceFieldData[i].fieldId) {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[i], false)
            this.deviceFieldData[i].flag = '未选中'
            break;
          }
        }
        this.selectDeviceSecondField = this.selectDeviceField
      }
      if (row.flag === '未选中' && this.selectDeviceSecondField === this.selectDeviceField) {
        this.selectDeviceSecondField = {}
        this.selectDeviceField = {}
      }
    },
    selectDeviceStatus(row) {
      if (row.flag === '已选中2') {
        return false
      }
      return true
    },

    handleDeleteOpc(index, row) {
      //删除表内映射信息
      this.selectOpcData.splice(index, 1)
      //删除总映射信息
      console.log("row",row);
      console.log("row",this.mapData);
      for (let i = 0; i < this.mapData.length; i++) {
        if (row.selectOpc === this.mapData[i].tableName && row.selectOpcField === this.mapData[i].tableData && row.selectDeviceField === this.mapData[i].deviceData && row.fieldId === this.mapData[i].fieldId) {
          console.log("*****")
          this.mapData.splice(i, 1);
          break;
        }
      }
      //恢复表内附属设备和数据源字段的状态
      for (let i = 0; i < this.deviceFieldData.length; i++) {
        if (row.selectDeviceField === this.deviceFieldData[i].name) {
          this.deviceFieldData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectDeviceField.toggleRowSelection(this.deviceFieldData[i], false)
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
          }, 500)
        }
      }
      for (let i = 0; i < this.opcInfoData.length; i++) {
        if (row.selectOpcField === this.opcInfoData[i].name) {
          this.opcInfoData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectOpc.toggleRowSelection(this.opcInfoData[i], false)
            this.$options.methods.selectOpcStatus(this.opcInfoData[i])
          }, 500)
        }
      }
      for (let m = 0; m < this.nowReflectData.Opc.length; m++) {
        if (this.opcName === this.nowReflectData.Opc[m].OpcName) {
          for (let n = 0; n < this.nowReflectData.Opc[m].opc.length; n++) {
            if (row.selectDeviceField === this.nowReflectData.Opc[m].opc[n].fieldName) {
              this.nowReflectData.Opc[m].opc.splice(n, 1)
              console.log("******************")
              break;
            }
          }
          break;
        }
      }
    },
    selectOpcMap() {
      if (this.selectOpc.name === undefined || this.selectDeviceField.name === undefined) {
        this.$message({
          message: '映射关系已经添加或映射内容是空的！',
          type: 'warning'
        });
      } else {
        console.log("1")
        for (let i = 0; i < this.nowReflectData.Opc.length; i++) {
          if (this.opcName === this.nowReflectData.Opc[i].OpcName) {
            console.log("2")
            this.nowOneReflectOpcName = this.nowReflectData.Opc[i]
          }
        }
        console.log(this.nowOneReflectOpcName)
        if (this.opcName === this.nowOneReflectOpcName.OpcName) {
          console.log("2")
          this.nowOneReflectOpc = JSON.parse(JSON.stringify(this.oneReflectOpc_temp))
          this.nowOneReflectOpc.opc = this.opcName
          this.nowOneReflectOpc.opcField = this.selectOpc.name
          this.nowOneReflectOpc.fieldName = this.selectDeviceField.name
          this.nowOneReflectOpc.fieldId = this.selectDeviceField.fieldId
          this.nowOneReflectOpcName.opc.push(this.nowOneReflectOpc)
          //显示一个任务的映射信息
          let data1 = {
            tableData: this.selectOpc.name,
            deviceData: this.selectDeviceField.name,
            tableName: this.opcName,
            fieldId: this.nowOneReflectOpc.fieldId
          }
          this.mapData.push(data1)
          let data2 = {
            selectOpc: this.opcName,
            selectDeviceField: this.selectDeviceField.name,
            selectOpcField: this.selectOpc.name,
            fieldId: this.nowOneReflectOpc.fieldId
          }
          this.selectOpcData.push(data2)
        }

        for (let i = 0; i < this.opcInfoData.length; i++) {
          if (this.selectOpc.name === this.opcInfoData[i].name) {
            this.opcInfoData[i].flag = '已选中2'
            this.$options.methods.selectOpcStatus(this.opcInfoData[i])
            break;
          }
        }
        for (let i = 0; i < this.deviceFieldData.length; i++) {
          if (this.selectDeviceField.fieldId === this.deviceFieldData[i].fieldId) {
            this.deviceFieldData[i].flag = '已选中2'
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
            break;
          }
        }
        this.selectOpc = {}
        this.selectDeviceField = {}
        this.selectOpcSecond = {}
        this.selectDeviceSecondField = {}

        for (let i = 0; i < this.nowReflectData.Opc.length; i++) {
          if (this.opcName === this.nowReflectData.Opc[i].OpcName) {
            this.nowReflectData.Opc[i] = JSON.parse(JSON.stringify(this.nowOneReflectOpcName))
          }
        }
        this.nowEachTaskData.reflectData = JSON.parse(JSON.stringify(this.nowReflectData))


        for (let i = 0; i < this.taskData.length; i++) {
          if (this.taskData[i].subEquipmentId === this.nowEachTaskData.subEquipmentId) {
            this.taskData[i] = JSON.parse(JSON.stringify(this.nowEachTaskData))
          }
        }
        this.allTaskData.taskData = this.taskData
      }
    },
    opcSure() {
      this.opcDialog = false
      this.$emit("opcSure", this.selectOpcData)
    },
    opcSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      this.selectOpc = row
      if (this.selectOpcSecond.name === undefined) {
        this.selectOpcSecond = this.selectOpc
      } else {
        for (let i = 0; i < this.opcInfoData.length; i++) {
          if (this.selectOpcSecond.name === this.opcInfoData[i].name) {
            this.$refs.selectOpc.toggleRowSelection(this.opcInfoData[i], false)
            this.opcInfoData[i].flag = '未选中'
            break;
          }
        }
        this.selectOpcSecond = this.selectOpc
      }
      if (row.flag === '未选中' && this.selectOpcSecond === this.selectOpc) {
        this.selectOpcSecond = {}
        this.selectOpc = {}
      }
      this.selectOpc = row;
    },
    selectOpcStatus(row) {
      if (row.flag === '已选中2') {
        return false
      }
      return true
    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'disabledCheck';
      }
    },
  },

}
</script>

<style scoped>
::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: '操作';
  text-align: center;
  line-height: 50px;
}
</style>
